import {useEffect, useState, useContext} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../context/AuthContext.js"

import Loader from "../components/Loader.js";
import "./MyProfile.css";


function MyProfile() {

  const navigate = useNavigate();
  const context = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState({});
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const data = {email, setEmail, name, setName, error};


  const disconnectUser = () => {
    context.setIsAuthenticate(false)
    context.setIsAdmin(false);
    localStorage.removeItem('Token');
    context.setToken(null);
    navigate('/');
  };




  useEffect(()=>{
    const headers = {'Content-Type': 'application/json'}
    if(context.token) {
      headers.Authorization = `Bearer ${context.token}`
    }
    //console.log(context);
    fetch(process.env.REACT_APP_BASEURL+'user/profile',{
      headers: new Headers(headers)
    })
    .then((res)=>
      res.json()
    )
    .then((res)=>{
      //console.log(res);
      if(res.success){
        setUser(res.user);
        setName(res.user.name);
        setEmail(res.user.email);
        setIsLoading(false);
      }
    })
  },[]);



  const handleEditProfile = (e) => {
    e.preventDefault();
    const headers = {'Content-Type': 'application/json'}
    if(context.token) {
      headers.Authorization = `Bearer ${context.token}`
    }else if(localStorage.getItem('Token')){
      headers.Authorization = `Bearer ${localStorage.getItem('Token')}`
    }
    fetch(process.env.REACT_APP_BASEURL+'user/update-profile', {
      headers: new Headers(headers),
      method: 'POST',
      body: JSON.stringify({
        email,
        name
      })
    }).then(res => res.json()).then(res => {
      if(res.success){
        setIsChanged(true);
        context.setUserInfos({name: name});
        setName(name);
        setTimeout(() => setIsChanged(false),2000);
      }
      else {
        setError('Failed to Update');
      }
    });
  };

  const ButtonMailto = ({ mailto, label }) => {
    return (
        <Link
            to='#'
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
    );
  };

  if(isLoading){
    return(
      <Loader />
    )
  }
  
  if(context.isAuthenticate) {
    return (
          <main id="myProfile">
          {isChanged ? <div className="modalInfo tac"><i className="fas fa-circle-check"></i><p>Profil mis à jour</p></div> : ''}
          <div className="wrap tac">
            <h1>{context.userInfos.name}</h1>
              <form className="mb10">
                <fieldset>
                  <input
                    id="email"
                    type="email"
                    placeholder="Email"
                    disabled
                    value={data.email}
                    onChange={(e)=>data.setEmail(e.target.value)}
                  />
                  <input
                    id="name"
                    type="text"
                    placeholder={name}
                    required
                    value={data.name}
                    onChange={(e)=>data.setName(e.target.value)}
                  />
                </fieldset>
              </form>
              <div className="tac mb30">
                <button type="submit" className="cta" onClick={handleEditProfile}>
                  <span>Modifier</span>
                </button>
              </div>
              
              <div className="mb20"><button className="ctaLink" onClick={disconnectUser}><i className="fas fa-power-off"></i> Se déconnecter</button></div>
              <div className="mb20"><button className="ctaLink mr20"><Link to="/change-password">Modifier le Mot de Passe</Link></button> <button className="ctaLink"><ButtonMailto label="Supprimer mon compte" mailto="mailto:contact@hoolapp.org" /></button></div>
            </div>
          </main>
    );
  }
}

export default MyProfile;
