import {Link} from "react-router-dom";

import "./NotFound.css"


function NotFound() {
    return (
      <main id="mainNotFound">
        <div className="wrap tac vec">
          <img src="hoola-notfound.png" alt="HoolApp didn't found the page"/>
          <h1 className="NotFound">Hoola ! 😦</h1>
          <p className="mb30">Il n'y a rien ici... Même pas un maux de tête</p>
          <div><Link className="cta" to='/'>Revenir en lieu sûr</Link></div>
        </div>
      </main>
    );
}

export default NotFound;
