//LIBS
import {useEffect, useState, useContext} from 'react';
import {AuthContext} from "../context/AuthContext.js"

//COMPONENTS
import Loader from "../components/Loader.js";
import RecordTab from "../components/RecordTab.js";

// STYLES
import "./MyRecords.css";






function MyRecords() {

  //const userLanguage = window.navigator.language;


  const context = useContext(AuthContext);
  const [records, setRecords] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{
    const headers = {'Content-Type': 'application/json'}
    //console.log(context);
    if(context.token) {
      headers.Authorization = `Bearer ${context.token}`
    }
    fetch('http://localhost:8000/user/profile', {
      headers: new Headers(headers),
    })
    .then((res)=>
      res.json()
    )
    .then((res)=>{
      //console.log(res);
      if(res.success){
        const recordTemp = [];
        for(let i = 0; i < res.user.records.length; i++) {
          recordTemp.push({
            id: res.user.records[i]._id,
            startTime: res.user.records[i].startTime,
            stopTime: res.user.records[i].stopTime,
            duration: res.user.records[i].duration,
            intensity: res.user.records[i].intensity,
            drugs: res.user.records[i].drugs,
            zones: res.user.records[i].zones,
            infos: res.user.records[i].infos,
          });
        }
        recordTemp.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));
        setRecords([...recordTemp]);
        setIsLoading(false);
      }
    })
  },[]);

  
  const handleDeleteRecord = (id) => {
    //console.log('handle id : ', id);
    const headers = {'Content-Type': 'application/json'}
     if(context.token) {
      headers.Authorization = `Bearer ${context.token}`
    }
    fetch(`http://localhost:8000/record/delete/${id}`, {
        headers: new Headers(headers),
        method: 'DELETE',
    })
    .then((res)=>
        res.json()
    )
    .then((res)=>{
      //console.log(res);
      if(res.success){
        const recordsTemp = [...records];
        const record = records.find(record => record.id === id);
        const index = records.indexOf(record);
        recordsTemp.splice(index, 1);
        setRecords([...recordsTemp]);
        setIsLoading(false);
      }
    })
  }



  if(isLoading){
    return(
      <Loader />
    )
  }
  return (
    <>
      <main id="MyRecords">
        <div className="wrap tac">
          <header>
            <h1>{!records.length ? 'Aucun enregistrement' : records.length > 0 ? 'Mes enregistrements' : ''}</h1>
            <p className="results">{records.length < 10 && records.length > 0 ? '0'+records.length : records.length} enregistrement(s)</p>
          </header>
        </div>
      </main>
      <section className="MyRecords">
        <div className="wrap tac">
          <ul className="recordList">
            {records.map((record, index)=>(
              <RecordTab record={record} key={index} handleDeleteRecord={()=>handleDeleteRecord(record.id)}></RecordTab>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
}

export default MyRecords;
