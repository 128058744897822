// React
import {useRef, useEffect, useState, useContext} from 'react';

// Libs
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

// Components
import {AuthContext} from "../context/AuthContext.js"
import Loader from "../components/Loader.js";
import StatTab from "../components/StatTab.js";

// Styles
import "./MyStatistic.css";


function MyStatistic() {


  // STATES
  const context = useContext(AuthContext);
  const [records, setRecords] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [calendarDays, setCalendarDays] = useState();
  const [startDay, setStartDay] = useState(new Date(new Date().setDate(new Date().getDate() - 29)));
  const [zoneChart, setZoneChart] = useState();
  const [drugChart, setDrugChart] = useState();

  // DATE LIBS

  const minutesToHours = (d) => {
    d = Number(d/1000);
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);
    let hDisplay = h > 0 ? h + (h === 1 ? "h" : "h") : "";
    let mDisplay = m > 0 ? m + (m === 1 ? "m" : "m") : "";
    let sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
    return hDisplay;
  }

  const secondsToHms = (d) => {
    d = Number(d/1000);
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);

    let hDisplay = h > 0 ? h + ":" : "";
    if(m < 10) {
      m = "0"+m;
    }
    let mDisplay = m > 0 ? m : "";
    let sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
    return hDisplay + mDisplay;
  }


  const millisToMinutes = (millis) => {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes;
  }

  const dateToFormatYYYY = (theDate) => {
    theDate = new Date(theDate.toString().split('GMT')[0]+' UTC').toISOString().split('T')[0];
    return theDate;
  }


  const usToFrFormat = (usFormat) => {
    let datePart = usFormat.match(/\d+/g),
    year = datePart[0].substring(2),
    month = datePart[1], day = datePart[2];
    return day+'/'+month+'/'+year;
  }

// END OF DATE LIBS




  // STRING TO HEX
  const fileNumToHex = (string) => {
    let result = string.toString(16);
    return (string);
  }


  // DATES VAR
  const next30 = new Date(new Date(startDay).setDate(new Date(startDay).getDate() + 29));
  const last30 = new Date(new Date(startDay).setDate(new Date(startDay).getDate() - 29));
  const currentDate = usToFrFormat(dateToFormatYYYY(new Date()));
  


  //PDF
  const contentArea = useRef(null);
	const handleExportWithFunction = (event) => {
		savePDF(contentArea.current, {
      fileName: new Date(Date.now()).getTime()+"-hoolapp-report.pdf"
    });
	};




  //
  useEffect(()=>{
    setCalendar(new Date(startDay));
    const headers = {'Content-Type': 'application/json'}
    if(context.token) {
      headers.Authorization = `Bearer ${context.token}`
    }
    fetch(process.env.REACT_APP_BASEURL+'user/profile', {
      headers: new Headers(headers),
    })
    .then((res)=>
      res.json()
    )
    .then((res)=>{
      //console.log(res.user.records);
      if(res.success){
        const recordTemp = [];
        for(let i = 0; i < res.user.records.length; i++) {
          recordTemp.push({
            startTime: res.user.records[i].startTime,
            stopTime: res.user.records[i].stopTime,
            duration: res.user.records[i].duration,
            intensity: res.user.records[i].intensity,
            drugs: res.user.records[i].drugs,
            zones: res.user.records[i].zones,
            infos: res.user.records[i].infos,
          });
        }
        recordTemp.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
        setRecords([...recordTemp]);
        setIsLoading(false);
      }
    })
  },[context.token]);
  


  const filterRecords = () => {
    if(!records) {
      return(false)
    }
    const recordsTemp = [...records];
    let fromDate = new Date(startDay);
    let toDate = new Date(next30);

    let filteredRecords = recordsTemp.filter(d => {var time = new Date(d.startTime).getTime();
      return (fromDate < time && time < toDate);
    });
    return(filteredRecords);
  }

  const filterLastMonthRecords = () => {
    const recordsTemp = [...records];
    let fromDate = new Date(startDay);
    let toDate = new Date(last30);

    let filteredRecords = recordsTemp.filter(d => {var time = new Date(d.startTime).getTime();
      return (toDate < time && time < fromDate);
    });
    return(filteredRecords);
  }

  const getRecordLength = () => {
    const recordTemp = filterRecords();
    return recordTemp.length;
  }

  const getPrevMonthRecordLength = () => {
    const recordTemp = filterLastMonthRecords();
    return recordTemp.length;
  }


  const getTotalDuration = () => {
    const recordTemp = filterRecords();
    let allDurations = 0;
    for(let i = 0; i < recordTemp.length; i++){
      allDurations = Number(allDurations) + Number(recordTemp[i].duration);
    }
    if(!allDurations > 0){
      return (0)
    }
    return(
      <span>{secondsToHms(allDurations)}</span>
    );
  }

  const createZoneChartArray = () => {
    const recordTemp = filterRecords();
    const zoneArray = [];
    const countArray = {};

    for(let i = 0; i < recordTemp.length; i++){
      for(let j = 0; j < recordTemp[i].zones.length; j++) {
        zoneArray.push(recordTemp[i].zones[j])
      }
    }
    zoneArray.forEach(function (x) {
      countArray[x] = (countArray[x] || 0) + 1;
    });

    const outputArray = Object.entries(countArray).map(([key, value]) => ({key,value}));
    
    return (
      outputArray
    ) 
  }

  const createDrugChartArray = () => {
    const recordTemp = filterRecords();
    const drugArray = [{}];
    const countArray = {};

    for(let i = 0; i < recordTemp.length; i++){
      //console.log(recordTemp[i].drugs);
      for(let j = 0; j < recordTemp[i].drugs.length; j++) {
        let drugElem = {
          key: recordTemp[i].drugs[j].drug.name,
          value: recordTemp[i].drugs[j].num
        };
        drugArray.push(
          drugElem
        )
      }
    }

    for(let i = 0; i < drugArray.length; i++) {
        //console.log('key : ', drugArray[i].key);
    }
    drugArray.forEach(function (x) {
      countArray[x] = (countArray[x] || 0) + 1;
    });

    const drugArrayFilter = drugArray.filter(key => Object.keys(key).length !== 0);

    const drugArraySum = drugArrayFilter.reduce((a,b)=>{
      let x = a.find(e=>e.key===b.key)
      if(!x) a.push(Object.assign({},b))
      else  x.value += b.value
      return a
    },[])

    return (
      drugArraySum
    ) 
  }

  useEffect(()=>{
    setZoneChart(createZoneChartArray());
    setDrugChart(createDrugChartArray());
    //console.log(drugChart);
  }, [records]);

  const removeTimeFromDate = (date) => {
    return (
      date.split('T')[0]
    );
  }

  const dateToString = (date) => {
  return(
    removeTimeFromDate(new Date(date.toString().split('GMT')[0]+' UTC').toISOString().split('.')[0].slice(0, -3))
    );
  }

  
  const intensityLook = (intensity, index) => {
    let intensityTab = intensity.split(',');
    
    for(let i = 0; i < intensityTab; i++) {
      //console.log('intensityLook : ', intensityTab[i]);
      switch (intensityTab[i]) {
        case '1':
            return (
            <span key={index} className="intensityInputWrap intensity01"></span>
            );
        case '2':
            return (
            <span key={index} className="intensityInputWrap intensity02"></span>
            );
        case '3':
            return (
            <span key={index} className="intensityInputWrap intensity03"></span>
            );
        case '4':
            return (
            <span key={index} className="intensityInputWrap intensity04"></span>
            );
        case '5':
            return (
            <span key={index} className="intensityInputWrap intensity05"></span>
            );
            default:
            return (
              <></>
            )
        }
    }
}

 
  // CREATE CALENDAR
  const setCalendar = (startDay, recordsTab) => {

    let dateArray = [];
    let numberDecal = startDay.getDay();


    if (numberDecal === 0) {
      numberDecal = 7;
    }
    numberDecal--;
    
    for( let i = numberDecal; i > 0; i-- ) {
      let stringDate = new Date(new Date(startDay).setDate(new Date(startDay).getDate() - i));
      let curDate = {
        stringDate: stringDate,
        isDisable: true
      };
      dateArray.push(curDate);
    }
    for( let i = 0; i < 30; i++) {
      let stringDate = new Date(new Date(startDay).setDate(new Date(startDay).getDate() + i));
      let dateRecords = [];
      if(recordsTab) {
        for(let i = 0; i < recordsTab.length; i++){
          if(dateToString(stringDate) == removeTimeFromDate(recordsTab[i].startTime)){
            dateRecords.push(recordsTab[i].intensity)
          }
        }
      }
      let curDate = {
        stringDate: stringDate,
        isDisable: false,
        dateRecords: dateRecords
      };
      //console.log('curdate : ',curDate);
      dateArray.push(curDate);
    }

    for(let i = 30; i < 42 -numberDecal; i++ ) {
      let stringDate = new Date(new Date(startDay).setDate(new Date(startDay).getDate() + i));
      let dateRecords = [];
      let curDate = {
        stringDate: stringDate,
        isDisable: true
      };
      dateArray.push(curDate);
    }
    
    setCalendarDays([...dateArray]);
  }


  useEffect(()=>{
    setCalendar(startDay, records);
  },[records])



  //UPDATE CALENDAR
  const updateCalendar = (dateValue) => {
    setStartDay(new Date(dateValue));
    setCalendar(new Date(dateValue), records);
  }


  const monthNames = ["Janv", "Févr", "Mars", "Avril", "Mai", "Juin",
    "Juill", "Aout", "Sept", "Oct", "Nov", "Déc"
  ];

  // DISABLE KEYBOARD ON INPUT
  const preventKeyboardInput = (e) => {
    e.preventDefault();
  }

  const getPercentage = (partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
  } 

  if(isLoading){
    return(
      <Loader />
    )
  }
  if(!records.length > 0) {
    return(
      <main id="MyStatistic">
        <div className="wrap col2 tac">
            <img className="happyHoola" src="hoola-happy.png" alt="Looting Logo"/>
            <div className="mainText">
              <h1 className="mb20">Analyse impossible</h1>
              <p className="mb10">Ici tu peux visualiser et exporter un rapport de tes maux de tête sur une période de 30 jours.</p>
              <p>Reviens dans quelques maux de tête !</p>
            </div>
        </div>
      </main>
    )
  }
  return (
    <PDFExport>
      <main id="MyStatistic">
        <div className="wrap col2 tac">
          <img className="happyHoola" src="hoola-happy.png" alt="Looting Logo"/>
          <div className="mainText">
            <h1 className="mb20">🧾 Au Rapport</h1>
            <p>Ici tu peux visualiser et exporter un rapport de tes maux de tête sur une période de 30 jours</p>
          </div>
        </div>
        <div className="wrap tac">
          <div className="dib mr20">
            <label
              htmlFor="startDay"
              className="label labelDate"
            >
            30 jours à partir
            </label>
            <input
              onKeyDown={(e)=>preventKeyboardInput(e)}
              id="startDay"
              name="startDay"
              type="date"
              required
              defaultValue={(dateToFormatYYYY(new Date(startDay)))}
              onChange={(e)=>updateCalendar(e.target.value)}
            />
          </div>
          <div className="dib">
            <button onClick={handleExportWithFunction} className="cta"><i className="fas fa-download"></i> Exporter</button>
          </div>
        </div>
      </main>

      <section className="MyStatistic" ref={contentArea}>
        <div className="wrap tac">
          
          <span className="userName">{context.userInfos.name+" - Rapport #"+new Date(Date.now()).getTime()+" - HoolApp.org"}</span>
          
          <div className="contentWrap">

            <h2 className="mb30"><span>Rapport HoolApp </span>{"du "+usToFrFormat(dateToFormatYYYY(new Date(startDay))).split('/')[0] + ' ' +monthNames[new Date(startDay).getUTCMonth()] +' '+ new Date(startDay).getFullYear()+" au "+usToFrFormat(dateToFormatYYYY(new Date(next30))).split('/')[0] + ' ' +monthNames[new Date(next30).getUTCMonth()] +' '+ new Date(next30).getFullYear()}</h2>
            
            <ul className="cardsWrap">
                <li className="elemStatCard">
                  <p className="statNum">{getRecordLength() < 10 ? '0' : ''}{getRecordLength()}</p>
                  <p className="statLabel">Enregistrement(s)</p>
                  <p className="statLabel">{"du "+usToFrFormat(dateToFormatYYYY(new Date(startDay)))+" au "+usToFrFormat(dateToFormatYYYY(new Date(next30)))}</p>
                </li>
                <li className="elemStatCard">
                  <p className="statNum"><img className="chartIcon" src={ (getRecordLength() > getPrevMonthRecordLength()) ? '/icon-up.svg' : ( getRecordLength() === getPrevMonthRecordLength()) ? '/icon-equal.svg' : '/icon-down.svg'} alt="Chart Icon" /></p>
                  <p className="statLabel">{getPrevMonthRecordLength() < 10  && getPrevMonthRecordLength() > 0 ? '0' : ''}{getPrevMonthRecordLength()} Enregistrement(s)</p>
                  <p className="statLabel">Les 30 jours anterieurs</p>
                </li>
                <li className="elemStatCard">
                  <p className="statNum"><img className="chartIcon" src="/meds.png" alt="Drugs Icon" /></p>
                  {
                    (drugChart.length < 1)
                    ? <p>Aucun médicament</p>
                    : drugChart.map((item, index)=>(
                      <p key={index} className="statLabel">
                        {item.key} : <span className="value">{item.value}</span>
                      </p>
                    ))
                  }
                </li>
            </ul>


            <div className="chartZone">
              <ul className="keys">
                {
                  zoneChart.map((item, index)=>(
                    <li key={index} >
                      <p>{item.key}</p>
                    </li>
                  ))
                }
                </ul>
                <ul className="bars">
                {
                  zoneChart.map((item, index)=>(
                    <li key={index}>
                      <div
                        className="bar"
                        style={{
                          width: getPercentage(item.value, zoneChart.length)+"%"
                        }}
                      ></div>
                      <span className="value">{item.value}</span>
                    </li>
                  ))
                }
              </ul>
            </div>
         
            <ul className="CalendarHead">
              <li>
                <p>lu</p>
              </li>
              <li>
                <p>ma</p>
              </li>
              <li>
                <p>me</p>
              </li>
              <li>
                <p>je</p>
              </li>
              <li>
                <p>ve</p>
              </li>
              <li>
                <p>sa</p>
              </li>
              <li>
                <p>di</p>
              </li>
            </ul>

            <ul className="StatsCalendar">
              {calendarDays.map((calendarDay, index)=>(
                <li key={index}>
                  <div className="dateWrap">
                    <p className="dateDay">{usToFrFormat(dateToFormatYYYY(calendarDay.stringDate)).split('/')[0]}</p>
                    <p className="dateMMYY">{monthNames[calendarDay.stringDate.getUTCMonth()] +' '+ calendarDay.stringDate.getFullYear()}</p>
                  </div>
                  <div className="intensityCalendarWrap">
                    {
                      calendarDay.dateRecords ?
                      calendarDay.dateRecords.map((dateRecord, index) =>(
                        intensityLook(String(dateRecord), index)
                      )) : ''
                    }
                  </div>
                  </li>
              ))}
            </ul>

            <ul className="statRecordList">
              {filterRecords().map((record, index)=>(
                <StatTab record={record} key={index}></StatTab>
              ))}
            </ul>
            
          </div>
        </div>
      </section>
      </PDFExport>
  );
}

export default MyStatistic;
