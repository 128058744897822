import {createContext, useState} from 'react';

export const UserThemeContext = createContext();

export default({children}) => {
    const[darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') || false);

    const values = {
    darkMode, setDarkMode
    }

    return (
    <UserThemeContext.Provider value={values}>
    {children}
    </UserThemeContext.Provider>
    )
}
