import { useState, useEffect } from "react";
import "./CookieModal.css";

const CookieModal = () => {

  const [isOpen, setIsOpen] = useState(true);

  const handleCookies = (e) => {
    e.preventDefault();
    localStorage.setItem("Cookies", "Cookies Accepted");
    setIsOpen(false);
  };

  useEffect(()=> {
    if(localStorage.getItem("Cookies")) {
        setIsOpen(false);
    }
  }, [isOpen]);

  return (
    isOpen &&
    <div className="modalCookie">
      <div className="cookieContent wrap tac">
        <p>Nous utilisons des cookies de performance 🍪 pour vous garantir la meilleure expérience.</p>
        <a onClick={handleCookies} className="smallCta white">OK</a>
      </div>
    </div>
  );
};

export default CookieModal;