import ReactDOM from "react-dom";
import {useState} from "react";
import "./modal.css";


const WarningModal = ({ isOpen, hide, warning, action}) => {

  const [isLoading, setIsLoading] = useState(true);

  return (
  isOpen &&
  ReactDOM.createPortal(
          <div className="modalContainer">
              <div className="overlay" onClick={hide}></div>
              <div className="modal">
                <div className="modalHeader">
                  <h4>⚠️ Attention</h4>
                  <button type="button" className="modal-close-button" onClick={hide}>
                    <span className="closeBtn">&times;</span>
                  </button>
                </div>
                <div className="modalContent tac">
                  <p className="mb20">{warning}</p>
                  <button onClick={action} type="submit" className="cta">
                    <span>Confirmer</span>
                  </button>
                </div>
              </div>
          </div>,
        document.body
      )
  )}
export default WarningModal;
