import { useEffect, useState, useContext } from 'react';
import {Link, useNavigate} from "react-router-dom";
import CookieModal from './CookieModal.js';

import "./footer.css";

const Footer = () => {

  return (
    <>
    <CookieModal />
    <footer id="globalFooter">
        <div className="wrap tac">
            <ul>
                <li><img height="50" className="logo" src="/logo.svg" alt="HoolApp Logo"/></li>
                <li><Link to="/about">À propos</Link></li>
                <li><Link to="/legals">Mentions Légales</Link></li>
                <li><a href="https://gofund.me/a8cf9144" target="_blank">Soutenir HoolApp</a></li>
                <li className="social">
                  <a href="https://www.facebook.com/HoolApp.org" target="_blank"><i className="fa-brands fa-facebook"></i></a>
                </li>
                <li className="social">
                  <a href="https://discord.gg/8QkgYAKK" target="_blank"><i className="fa-brands fa-discord"></i></a>
                </li>
            </ul>
        </div>
        
        <div className="subFooter tac">
            <p>Copyright © 2022 — HoolApp — All Rights Reserved</p>
        </div>

    </footer>
    </>
  )


}
export default Footer;
