import {useState, useContext, useEffect} from 'react'
import {BrowserRouter, Routes, Route, Link, Navigate} from "react-router-dom"


// CONTEXT
import {AuthContext} from "./context/AuthContext.js"
import AuthProvider from "./context/AuthContext.js"

import {UserThemeContext} from "./context/UserThemeContext.js"
import UserThemeProvider from "./context/UserThemeContext.js"

// PAGES
import NavBar from "./components/navBar.js"
import HomePage from "./views/HomePage.js"
import NotFound from "./views/NotFound.js"
import MyProfile from "./views/MyProfile.js"
import MyDrugs from "./views/MyDrugs.js"
import MyRecords from "./views/MyRecords.js"
import MyStatistic from "./views/MyStatistic.js"
import AccountValidation from "./views/AccountValidation.js"
import ChangePassword from "./views/ChangePassword.js"
import Legals from "./views/Legals.js"
import About from "./views/About.js"
import Footer from "./components/footer.js"

// STYLES
import './App.css';

// PRIVATE USERS ROUTES
const PrivateRoute = ({children}) => {
  const context = useContext(AuthContext)
  return (
    context.isAuthenticate ? children : <Navigate to={window.location.pathname} />
  )
}
// PRIVATE ADMIN ROUTES
const PrivateAdminRoute = ({children}) => {
  const context = useContext(AuthContext)
  return (
    context.isAuthenticate && context.isAdmin ? children : <Navigate to={window.location.pathname} />
  )
}

const UserTheme = ({children}) => {
  const context = useContext(UserThemeContext);
  return (
    <div className={context.darkMode ? 'globalContainer darkMode' : 'globalContainer'}>{children}</div>
  )
}


function App(){
  return(
      <AuthProvider>
        <UserThemeProvider>
          <BrowserRouter>
            <UserTheme>
              <NavBar />
              <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/account-validation/:activationToken' element={<AccountValidation />} />
                <Route path='/change-password' element={<ChangePassword />} />
                <Route path='/profile' element={<PrivateRoute><MyProfile /></PrivateRoute>} />
                <Route path='/drugs' element={<PrivateRoute><MyDrugs /></PrivateRoute>} />
                <Route path='/records' element={<PrivateRoute><MyRecords /></PrivateRoute>} />
                <Route path='/reports' element={<PrivateRoute><MyStatistic /></PrivateRoute>} />
                <Route path='/about' element={<About />} />
                <Route path='/legals' element={<Legals />} />
                <Route path='*' element={<NotFound />} />
              </Routes>
              <Footer />
            </UserTheme>
          </BrowserRouter>
        </UserThemeProvider>
      </AuthProvider>
  );
} // END APP

export default App;
