import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
// import Loader from "../components/Loader.js";
//import Footer from "../components/footer.js";

import "./ChangePassword.css";

function ChangePassword() {
      
    const navigate = useNavigate();
    const search = useLocation().search;
    const token =new URLSearchParams(search).get('tokenForget')||localStorage.getItem('Token');
    const [isLoading, setIsLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState();
    const [error, setError] = useState();
    const data = {password, setPassword, confirmPassword, setConfirmPassword, passwordError, setPasswordError};

    const handleChangePassword = (e) => {

        e.preventDefault();
    
        if(password == confirmPassword){
          fetch(process.env.REACT_APP_BASEURL+'user/change-password', {
            headers: {
              'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
              password,
              confirmPassword,
              token,
            })
          }).then(res => res.json()).then(res => {
            setPasswordError("");
            if(res.success){
                setSuccess(true);
                if(!localStorage.getItem('Token')){
                    localStorage.setItem('Token', res.token);
                }
                setTimeout(() => {navigate('/profile')},4000);
                //window.location.reload();
            } else {
              setError({
                confirmPassword: res.msg
              });
            }
          });
        } else {
          setPasswordError('errorInput');
          setError({
            confirmPassword: 'Password does not match'
          });
        }
      };
      
    // if(isLoading){
    //     return(
    //         <Loader />
    //     )
    // }
    
    if(success){
        return (
          <main className="ChangePasswordMain">
            <div className="wrap tac">
              <h1 className="mb10"><i className="fas fa-circle-check"></i></h1>
              <p className="mb10">Le mot de passe a été modifié</p>
              <p>Vous allez être redirigé</p>
            </div>
          </main>
        )
      }
    return (
    <>
        <main className="ChangePasswordMain">
            <div className="wrap tac">
              <h1 className="mb20">Modifier le mot de passe</h1>
              <p className="mb30">Veillez à noter votre nouveau mot de passe</p>
              <form onSubmit={handleChangePassword}>
                  <div className="formWrap">
                      <div>
                        <input
                            id="password"
                            type="password"
                            placeholder="Mot de passe"
                            required
                            value={data.password}
                            onChange={(e)=>data.setPassword(e.target.value)}
                        />
                      </div>
                      <div>
                        <input
                            id="confirmPassword"
                            type="password"
                            placeholder="Confirmation"
                            required
                            value={data.confirmPassword}
                            onChange={(e)=>data.setConfirmPassword(e.target.value)}
                        />
                      </div>
                  </div>
                  <p>{passwordError ? 'Password does not match' : ''}</p>
                  <div className="tac mt30">
                      <button type="submit" className="cta">
                        <span>Change Password</span>
                      </button>
                  </div>
              </form>
            </div>
        </main>
    </>
    )
}

export default ChangePassword