import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import Loader from "../components/Loader.js";
import LoginModal from "../components/loginModal";

import "./AccountValidation.css";

function AccountValidation() {

    const {activationToken} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [success, setSuccess] = useState(false);

    const [isOpenLogin, setIsOpenLogin] = useState(false);
    const toggleModalLogin = () => { setIsOpenLogin(!isOpenLogin); }

    useEffect(() =>{
        fetch(`https://api.hoolapp.org/user/account-validation/${activationToken}`)
        .then((res) =>res.json())
        .then((res) => {
            console.log(res);
            if(res.success) {
                setSuccess(true);
            }
            setIsLoading(false);
        })
    },[])

    if(isLoading){
        return(
            <Loader />
        )
    }

    return (
    <>
        <LoginModal isOpen={isOpenLogin} hide={toggleModalLogin}/>
        <main className="AccountValidationMain">
            <div className="wrap tac vec">
                <img className="happyHoola" src="/hoola-happy.png" alt="Happy Hoola"/>
                <h1>{success ? 'Compte Activé 😉' : 'NO SUCCESS 😪'}</h1>
                <p>Le compte a bien été activé.<br/>Tu peux maintenant prendre tes maux de tête en main !</p>
                <button className="cta" onClick={toggleModalLogin}>Connexion</button>
            </div>
        </main>
    </>
    )
}

export default AccountValidation