import {useState} from "react";

import "./backzones.css";

const BackZones = ({handleZoneClick}) => {

    return (
        <svg
        xmlns='http://www.w3.org/2000/svg'
        x='0'
        y='0'
        enableBackground='new 0 0 445.3 645.6'
        version='1.1'
        viewBox='0 0 445.3 645.6'
        xmlSpace='preserve'
        id='BackZones'
        >
        <g id="backHead">
            <path
            d='M224.1 641c-38.4 0-74.6-7.5-101.9-21-27.7-13.8-42.9-32.3-42.9-52v-.3l19.8-118.8c-5.4-7.8-11.4-15.4-17.2-22.8-8.6-11-17.6-22.3-25-34.6-5.7-9.4-10.5-19.4-14.4-29.9-8.8-.6-15.5-9.1-18.6-16.5-6.4-15.2-6.1-32.1-5.9-48.3.1-5.6.2-11.4 0-17-.5-14.2-2.8-28.3-7-41.9-.3-.9-.6-1.8-.8-2.7-2.1-6.8-4.3-13.8-3.5-21 1-8.1 7.4-16.8 16.2-16.8h1c4.7.3 8.7 3.2 11.6 5.6.8-8.7 1.6-18.5 2.2-28.1.3-4.8.5-9.8.7-14.6.7-14.4 1.3-29.3 3.7-43.8 2.4-14.2 7.6-35.3 19.3-55 11.1-18.7 25.4-31.8 42.4-39 19.1-8 39.8-10.2 61.1-11.8C187.6 8.8 204.8 8 221 8h6.2c16.1 0 33.4.8 56.1 2.5 21.3 1.6 42 3.7 61.1 11.8 17.1 7.2 31.3 20.3 42.4 39 11.7 19.7 16.9 40.8 19.3 55 2.4 14.5 3.1 29.4 3.7 43.8.2 4.8.4 9.7.7 14.6.6 9.6 1.4 19.4 2.2 28.1 2.9-2.4 6.9-5.2 11.6-5.6h1c8.8 0 15.2 8.7 16.2 16.8.9 7.3-1.3 14.3-3.5 21-.3.9-.6 1.8-.8 2.7-4.1 13.6-6.5 27.7-7 41.9-.2 5.6-.1 11.4 0 17 .3 16.3.5 33.1-5.9 48.3-3.1 7.3-9.8 15.9-18.6 16.5-3.9 10.5-8.8 20.5-14.4 29.9-7.4 12.3-16.3 23.7-25 34.6-5.8 7.4-11.8 15-17.2 22.8l19.8 118.8v.3c0 19.8-15.2 38.3-42.9 52-27.3 13.7-63.5 21.2-101.9 21.2z'
            className='bzoneColor0'
            ></path>
            {/* <path
            d='M227.2 10c18.6 0 37.3 1.1 55.9 2.5 20.5 1.6 41.4 3.6 60.4 11.6 36 15.2 54.4 55.9 60.5 92.5 3.2 19.2 3.3 38.7 4.5 58.2.6 10.7 1.6 21.4 2.6 32.1.9-.8 1.8-1.7 2.8-2.5 3.1-2.6 6.6-4.9 10.6-5.2h.8c7.4 0 13.3 7.6 14.2 15.1.9 7.8-1.9 15.4-4.2 22.9-4.2 13.7-6.6 28-7.1 42.4-.7 21.8 2.7 44.5-5.8 64.6-3.2 7.6-9.9 15.3-17.7 15.3h-.5c-3.9 10.8-8.9 21.2-14.7 30.9-12.5 20.7-29 38.3-42.6 58L366.8 568c0 39.2-63.9 71-142.8 71S81.2 607.2 81.2 568l19.9-119.6c-13.6-19.7-30.1-37.3-42.6-58-5.9-9.7-10.8-20.1-14.7-30.9h-.5c-7.8 0-14.5-7.7-17.7-15.3-8.4-20.1-5-42.9-5.8-64.6-.5-14.4-2.9-28.7-7.1-42.4-2.3-7.5-5.1-15.1-4.2-22.9.9-7.5 6.8-15.1 14.2-15.1h.8c4 .3 7.5 2.7 10.6 5.2 1 .8 1.9 1.6 2.8 2.5 1-10.7 1.9-21.4 2.6-32.1 1.1-19.4 1.3-39 4.5-58.2 6.1-36.6 24.5-77.3 60.5-92.5 19-8 39.9-10.1 60.4-11.6 18.6-1.4 37.3-2.5 55.9-2.5h6.4m0-4H221c-16.2 0-33.5.8-56.2 2.5-21.5 1.6-42.3 3.8-61.7 11.9C85.6 27.8 71 41.2 59.7 60.3c-11.8 20-17.1 41.3-19.5 55.7-2.4 14.6-3.1 29.6-3.8 44-.2 4.8-.4 9.7-.7 14.5-.5 8.3-1.2 16.7-1.9 24.4-2.7-1.9-6-3.5-9.8-3.8h-1.1c-9.9 0-17.1 9.7-18.2 18.6-.9 7.7 1.4 14.9 3.5 21.9.3.9.6 1.8.8 2.7 4.1 13.4 6.4 27.3 6.9 41.4.2 5.6.1 11.3 0 16.9-.3 16.5-.5 33.5 6.1 49.1 3.2 7.5 9.9 16.2 19 17.5 3.9 10.2 8.6 19.9 14.1 29.1 7.5 12.4 16.4 23.8 25.1 34.8 5.6 7.2 11.4 14.5 16.7 22.1l-19.6 118c0 .2-.1.4-.1.7 0 20.6 15.6 39.7 44 53.8 27.5 13.7 64 21.2 102.7 21.2s75.2-7.5 102.7-21.2c28.4-14.1 44-33.2 44-53.8 0-.2 0-.4-.1-.7l-19.6-118c5.3-7.5 11.1-14.9 16.7-22.1 8.7-11 17.6-22.4 25.1-34.8 5.5-9.1 10.3-18.9 14.1-29.1 9.1-1.3 15.8-10 19-17.5 6.6-15.6 6.3-32.7 6.1-49.1-.1-5.6-.2-11.4 0-16.9.5-14 2.8-27.9 6.9-41.4.3-.9.6-1.8.8-2.7 2.2-7 4.4-14.2 3.5-21.9-1.1-8.9-8.3-18.6-18.2-18.6h-1.1c-3.8.3-7.1 1.9-9.8 3.8-.7-7.7-1.4-16.2-1.9-24.4-.3-4.8-.5-9.8-.7-14.5-.7-14.5-1.4-29.4-3.8-44-2.4-14.4-7.7-35.7-19.5-55.7-11.3-19.1-25.9-32.5-43.4-39.9-19.3-8.1-40.2-10.3-61.7-11.9C260.7 6.8 243.4 6 227.2 6z'
            className='bzoneColor1'
            ></path> */}
        </g>
        <path
            onClick={handleZoneClick}
            data-zone="Parietale gauche"
            d='M104.6 24.1c-36 15.2-54.4 55.9-60.5 92.5-3.2 19.2-3.3 38.7-4.5 58.2-.4 6.1-.8 12.2-1.3 18.4 27.6 6.8 67.9 16.5 91.5 20.9 38.5 7.2 94.3 7.2 94.3 7.2V10c-19.7-.1-39.4 1-59 2.5-20.6 1.5-41.5 3.6-60.5 11.6z'
            className='bzoneColor0'
        ></path>
        <path
            onClick={handleZoneClick}
            data-zone="Parietale droit"
            d='M343.6 24.1c36 15.2 54.4 55.9 60.5 92.5 3.2 19.2 3.3 38.7 4.5 58.2.4 6.1.8 12.2 1.3 18.4-27.6 6.8-67.9 16.5-91.5 20.9-38.5 7.2-94.3 7.2-94.3 7.2V10c19.7-.1 39.4 1 59 2.5 20.6 1.5 41.5 3.6 60.5 11.6z'
            className='bzoneColor0'
        ></path>
        <path
            onClick={handleZoneClick}
            data-zone="Occipitale gauche"
            d='M129.8 214c-23.6-4.4-63.9-14.1-91.5-20.9-.4 4.6-.8 9.2-1.2 13.7-.9-.8-1.8-1.7-2.8-2.5-3.1-2.6-6.6-4.9-10.6-5.2-7.8-.6-14.1 7.3-15 15-.9 7.8 1.9 15.4 4.2 22.9 4.2 13.7 6.6 28 7.1 42.4.7 21.8-2.7 44.5 5.8 64.6 3.3 7.7 10.1 15.6 18.1 15.3 3.9 10.8 8.9 21.2 14.7 30.9 12.5 20.7 29 38.3 42.6 58l-.1.4c8.2 1.5 35.2 6.3 53.6 8 21.9 2.1 69.4 3.9 69.4 3.9V221.3s-55.8-.1-94.3-7.3z'
            className='bzoneColor0'
        ></path>
        <path
            onClick={handleZoneClick}
            data-zone="Occipitale droit"
            d='M435.3 237.1c2.3-7.5 5.1-15.1 4.2-22.9-.9-7.8-7.2-15.6-15-15-4 .3-7.5 2.7-10.6 5.2-1 .8-1.9 1.6-2.8 2.5-.4-4.6-.8-9.2-1.2-13.7-27.6 6.8-67.9 16.5-91.5 20.9-38.5 7.2-94.3 7.2-94.3 7.2v239.5s39.9-.3 64.1-3.4c16.1-2 42.8-6.3 58.8-9v-.1c13.6-19.7 30.1-37.3 42.6-58 5.9-9.7 10.8-20.1 14.7-30.9 8 .3 14.9-7.5 18.1-15.3 8.4-20.1 5-42.9 5.8-64.6.5-14.3 2.9-28.6 7.1-42.4z'
            className='bzoneColor0'
        ></path>
        <path
            onClick={handleZoneClick}
            data-zone="Cervicales gauches"
            d='M101.1 448.7L81.3 567.9c0 39.2 63.9 71 142.8 71V460.7s-47.5-1.8-69.4-3.9c-18.3-1.8-45.4-6.6-53.6-8.1z'
            className='bzoneColor0'
        ></path>
        <path
            onClick={handleZoneClick}
            data-zone="Cervicales droites"
            d='M347 448.4c-16 2.6-42.7 6.9-58.8 9-24.2 3-64.1 3.4-64.1 3.4V639c78.9 0 142.8-31.8 142.8-71L347 448.4z'
            className='bzoneColor0'
        ></path>
        <g id="backLines">
            <path d='M224.1 10L224.1 639' className='bzoneColor2'></path>
            <path
            d='M38.3 192.7c43.3 13.7 107.3 28.6 185.7 28.6s142.4-14.9 185.7-28.6'
            className='bzoneColor2'
            ></path>
            <path
            d='M101.2 448.3c33.1 6.8 74.7 12.4 122.9 12.4 33.8 0 75.7-2.8 122.9-12.4'
            className='bzoneColor2'
            ></path>
        </g>
        </svg>
    )
}

export default BackZones;