import {useEffect, useState, useContext} from 'react';
import {Link} from "react-router-dom";

import {AuthContext} from "../context/AuthContext.js"

//import Loader from "../components/Loader.js";
import Modal from "../components/modal";
import LoginModal from "../components/loginModal";
import RecordModal from "../components/recordModal";
import HoolaRobot from '../components/HoolaRobot.js';

import "./HomePage.css";



function HomePage(){

  const context = useContext(AuthContext);

  // MODALS
  const [isOpenRegister, setIsOpenRegister] = useState(false);
  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [isOpenRecord, setIsOpenRecord] = useState(false);

  const toggleModalRegister = () => { setIsOpenRegister(!isOpenRegister); }
  const toggleModalLogin = () => { setIsOpenLogin(!isOpenLogin); }
  const toggleModalRecord = () => { setIsOpenRecord(!isOpenRecord); }
  


  //const [error, setError] = useState({});


  useEffect(()=>{
    const headers = {'Content-Type': 'application/json'}
    if(context.token) {

      headers.Authorization = `Bearer ${context.token}`

      fetch(process.env.REACT_APP_BASEURL+'user/profile', {
        headers: new Headers(headers),
      })
      .then((res)=>
        res.json()
      )
      .then((res)=>{
        if(res.success){
          //console.log(res);
          context.setIsRecording(res.user.isRecording)
        }
      })
   }
  }, [context]);


  const toggleRecord = (e) => {
    e.preventDefault();
    const todayNow = new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0].slice(0, -3);
    //console.log('Today NOW : ', todayNow);
    if(context.isRecording) {
      context.setIsRecording(!context.isRecording);
      //console.log('STOP RECORD');
      toggleModalRecord();
    }
    else {
      context.setIsRecording(!context.isRecording);
      //console.log('START RECORD');
      context.setStartTime(todayNow);
      const headers = {'Content-Type': 'application/json'}
      if(context.token) {
        headers.Authorization = `Bearer ${context.token}`
      }
      fetch('https://api.hoolapp.org/record', {
        headers: new Headers(headers),
        method: 'POST',
        body: JSON.stringify({
          startTime: todayNow
        })
      })
      .then((res)=>
        res.json()
      )
      .then((res)=>{
        //console.log(res);
        if(res.success){
          context.setStartTime(res.record.startTime);
        }
      })
    }
  }

  //console.log('StartTime Homepage : ', context.startTime);

  if(context.isAuthenticate) {
    return (
      <>
      <RecordModal isOpen={isOpenRecord} hide={toggleModalRecord}/>
      <main id="homeMainLogged" className="homeMain">
        <div className="wrap tac vec">
          {<HoolaRobot anim="hello"></HoolaRobot>}
          <div className="content">
            <h1>Hello 👋 {context.userInfos.name}</h1>
            <ul>
              <li><Link to='/records'><i className="fas fa-list"></i> Enregistrements <i className="chevron fas fa-chevron-right"></i></Link></li>
              <li><Link to='/reports'><i className="fas fa-file-export"></i> Rapports <i className="chevron fas fa-chevron-right"></i></Link></li>
              <li><Link to='/drugs'><i className="fas fa-capsules"></i> Médicaments <i className="chevron fas fa-chevron-right"></i></Link></li>
            </ul>
          </div>

          <div className={context.isRecording ? 'btnStartWrap isRecording' : 'btnStartWrap'} >
            <button onClick={toggleRecord} className="btnStart"><i className={context.isRecording ? 'fas fa-stop' : 'fas fa-bolt'}></i></button>
            <span className="btnLabel">{context.isRecording ? "Stop" : "Aie !"}</span>
          </div>
        </div>
      </main>
      </>
    )
  }
  return(
    <>
    <div className="mainSvgBg"></div>
    <LoginModal isOpen={isOpenLogin} hide={toggleModalLogin}/>
    <Modal isOpen={isOpenRegister} hide={toggleModalRegister} />
    <main id="homeMain" className="homeMain notLogged">
      <div className="wrap tac vec">
      {<HoolaRobot anim="hello"></HoolaRobot>}
        <div className="mainContent">
          <h1>Prenons tes maux<br/>de tête en main</h1>
          <button className="cta" onClick={toggleModalRegister}>Commencer ici</button>
          <p className="mt20">Déjà un compte ? <button className="ctaLink" onClick={toggleModalLogin}>Connexion</button></p>
        </div>
      </div>
    </main>

    <section className="about01">
      <div className="wrap flex">
        <img src="/app-screen.png" alt="Illustration d'un aperçu de l'application" />
        <div className="content">
          <h2 className="mb30">Mieux suivre vos maux de tête</h2>
          <ul>
            <li className="mb20">
              <span><i className="fas fa-shield-halved"></i></span>
              <p>Gratuit, pratique et sécurisé</p>
            </li>
            <li className="mb20">
              <span><i className="fas fa-hand-holding-medical"></i></span>
              <p>Mieux comprendre vos maux de tête: typologie, déclencheurs...</p>
            </li>
            <li className="mb20">
              <span><i className="fas fa-user-doctor"></i></span>
              <p>Communiquer avec votre médecin plus facilement</p>
            </li>
            <li className="mb20">
              <span><i className="fas fa-file-medical"></i></span>
              <p>Obtenir un rapport mensuel</p>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section className="aboutWhy mb50">
        <div className="wrap flex">
          <div>
            <h2 className="mb30">Pourquoi HoolApp</h2>
            <p className="mb20">HoolApp a été créé avec passion et empathie, pour mieux prendre en charge les maux de tête.</p>
            <p className="mb20">Vos données vous appartiennent, elles ne sont jamais réutilisées. C'est gratuit car nous sommes des gens biens 😎</p>
            <p>Ergonomique, crosseplatforme et éco-conceptualisé.</p>
          </div>
          <div>
            <h2 className="mb30">Comment ça marche</h2>
            <p className="mb20">À chaque mal de tête, rendez-vous sur HoolApp pour appuyer sur le bouton d'enregistrement.</p>
            <p className="mb20">Une fois la douleur dissipée, revenez sur l'App pour terminer l'enregistrement.</p>
            <p>Mesurez vos maux de tête, chaque mois.</p>
          </div>
        </div>
      </section>
      <hr className="wrap"/>
      <section className="justDoIt">
        <div className="wrap tac">
          <img src="/headache.png" alt="Illustration d'un aperçu de l'application" />
          <p className="quote tac"><strong>Il ne vous reste plus qu'à prendre vos maux de tête en main !</strong></p>
        </div>
      </section>
    </>
  )
} // END HOMEPAGE


export default HomePage;
