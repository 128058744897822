import {useEffect, useState, useContext} from 'react';
import {AuthContext} from "../context/AuthContext.js"

import Loader from "../components/Loader.js";
import DrugModal from "../components/drugModal.js";

import "./MyDrugs.css";

function MyDrugs() {

  const context = useContext(AuthContext);
  const [drugs, setDrugs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  const [isOpenDrugModal, setIsOpenDrugModal] = useState(false);
  const toggleModalDrug = () => {
    setIsOpenDrugModal(!isOpenDrugModal);
  }


  useEffect(()=>{
    const headers = {'Content-Type': 'application/json'}
    if(context.token) {
      headers.Authorization = `Bearer ${context.token}`
    }
    fetch(process.env.REACT_APP_BASEURL+'drug/mydrugs', {
      headers: new Headers(headers)
    })
    .then((res)=>
      res.json()
    )
    .then((res)=>{
      if(res.success){
        //console.log(res.drugs);
        setDrugs(res.drugs)
        setIsLoading(false);
      }
    })
    .catch((e) => {
      //console.log(e);
    })
  },[]);




  // const handleDeleteDrug = (id) => {
  //   const headers = {'Content-Type': 'application/json'}
  //   if(context.token) {
  //     headers.Authorization = `Bearer ${context.token}`
  //   }
  //   setIsLoading(true);
  //   fetch(`http://localhost:8000/drug/delete/${id}`, {
  //     headers: new Headers(headers),
  //     method: 'DELETE',
  //   })
  //   .then((res)=>
  //     res.json()
  //   )
  //   .then((res)=>{
  //     if(res.success){
  //       let drugsTemp = [...drugs];
  //       drugsTemp = drugsTemp.filter((drug) => {
  //         if(drug._id !== id) {
  //           return drug
  //         }
  //       });
  //       setDrugs([...drugsTemp]);
  //       setIsLoading(false);
  //     }
  //   })
  // }


  const handleEditDrug = (id, archiveState) => {
    //console.log('HandleEditDrug : ',id, archiveState);
    //e.preventDefault();
    const headers = {'Content-Type': 'application/json'}
    if(context.token) {
      headers.Authorization = `Bearer ${context.token}`
    }else if(localStorage.getItem('Token')){
      headers.Authorization = `Bearer ${localStorage.getItem('Token')}`
    }
    fetch(`https://api.hoolapp.org/drug/update/${id}`, {
      headers: new Headers(headers),
      method: 'POST',
      body: JSON.stringify({
        isArchived: !archiveState
      })
    }).then(res => res.json()).then(res => {
      if(res.success){
        //console.log('res : ',res);
        const drugsTemp = [...drugs];
        //console.log('drugs success : ', drugs);
        const drug = drugs.find(drug => drug._id === id);
        const index = drugs.indexOf(drug);
        drugsTemp.splice(index, 1);
        drugsTemp.push(res.drug);
        //console.log('drugsTemp : ',drugsTemp);
        setDrugs([...drugsTemp]);

        setIsLoading(false);
      }
      else {
        setError('Failed to Update');
      }
    });
  };



    if(isLoading){
      return(
        <Loader />
      )
    }
    return (
      <>
      <DrugModal userid={context.userInfos._id} drugs={drugs} setDrugs={setDrugs} isOpen={isOpenDrugModal} hide={toggleModalDrug}/>
      <main id="MyDrugs" className="mb30">
        <div className="wrap tac">
          <header>
            <h1 className="mb20">{drugs.length < 1 ? 'Aucun médicament 💊' : drugs.length < 2 ? 'Mon Médicament 💊' : 'Mes Médicaments 💊'}</h1>
          </header> 
        </div>
      </main>
      <section>
        <div className="wrap tac">
          <ul className="drugList">
            {drugs.map((drug, index)=>(
              <li className={drug.isArchived ? 'elemDrug mt20 isArchived' : 'elemDrug mt20'} key={index}>
                <p>{drug.name}</p>
                <button className="smallCta ctaDel" onClick={() => handleEditDrug(drug._id, drug.isArchived)}><i className={drug.isArchived ? 'fas fa-eye' : 'fas fa-eye-slash'}></i><span>{drug.isArchived ? 'Rétablir' : 'Archiver'}</span></button>
              </li>
            ))}
          </ul>
          <button onClick={toggleModalDrug} className="cta circle"><i className="fas fa-plus"></i> </button>
        </div>
      </section>
    </>
    );
}

export default MyDrugs;
