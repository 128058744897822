//STYLES
import "./HoolaRobot.css";

function HoolaRobot(anim) {

    const animClass = anim.anim;

    return (
    <svg
        id="HoolaRobot"
        className={animClass}
        x="0"
        y="0"
        enableBackground="new 0 0 313 370"
        version="1.1"
        viewBox="0 0 313 370"
        xmlSpace="preserve"
    >
        <g>
            <path
                d="M221.91 385.08H179.3a69.16 69.16 0 01-69.16-69.17v-58a35.39 35.39 0 0135.39-35.4h110.15a35.4 35.4 0 0135.4 35.4v58a69.17 69.17 0 01-69.17 69.17z"
                className="color1"
                transform="translate(-52.78 -14.92)"
            ></path>
            <path
                d="M221.91 373.49H179.3a69.16 69.16 0 01-69.16-69.17v-58A35.38 35.38 0 01145.53 211h110.15a35.39 35.39 0 0135.4 35.39v58a69.17 69.17 0 01-69.17 69.1z"
                className="color2"
                transform="translate(-52.78 -14.92)"
            ></path>
            <path
                d="M233.8 276.66h-15.61a4.33 4.33 0 01-4.33-4.33v-15.61a4.33 4.33 0 00-4.33-4.33h-14a4.33 4.33 0 00-4.33 4.33v15.61a4.33 4.33 0 01-4.34 4.33h-15.6a4.33 4.33 0 00-4.33 4.33v14a4.33 4.33 0 004.33 4.33h15.6a4.34 4.34 0 014.34 4.34v15.6a4.33 4.33 0 004.33 4.33h14a4.33 4.33 0 004.33-4.33v-15.6a4.33 4.33 0 014.33-4.34h15.61a4.33 4.33 0 004.33-4.33V281a4.33 4.33 0 00-4.33-4.34z"
                className="color3"
                transform="translate(-52.78 -14.92)"
            ></path>
        </g>
        <g className="rightArm">
            <path
                d="M131.19 232.14c-16.72-8.27-36.21 3.31-49.59 13a.33.33 0 00-.18 0c-13.48 9.63-31 24.59-28.38 43.25a17.32 17.32 0 007.06 12c4.59 3.07 10.55 3.21 16 2.36 12.06-1.89 22.86-7.92 32.81-15.19 11.55-7.75 22.36-16.81 28.67-29.05 2.53-4.91 4.27-10.6 2.81-15.92a17.36 17.36 0 00-9.2-10.45z"
                className="color1"
                transform="translate(-52.78 -14.92)"
            ></path>
            <path
                d="M134.7 226.65c-16.72-8.27-36.2 3.31-49.58 13a.27.27 0 00-.18 0c-13.49 9.64-31 24.59-28.39 43.25a17.36 17.36 0 007.07 12c4.59 3.07 10.54 3.21 16 2.36 12.06-1.88 22.86-7.92 32.81-15.19 11.55-7.75 22.36-16.8 28.67-29 2.52-4.9 4.27-10.6 2.8-15.92a17.32 17.32 0 00-9.2-10.5z"
                className="color2"
                transform="translate(-52.78 -14.92)"
            ></path>
        </g>
        <g className="leftArm">
            <path
                d="M268.81 223.78c16.72-8.27 36.21 3.31 49.59 13a.27.27 0 01.18 0c13.48 9.63 31 24.59 28.38 43.25a17.32 17.32 0 01-7.06 12c-4.59 3.07-10.55 3.21-16 2.36-12.06-1.89-22.86-7.92-32.81-15.19-11.55-7.75-22.36-16.81-28.67-29-2.53-4.91-4.27-10.6-2.81-15.92a17.36 17.36 0 019.2-10.5z"
                className="color1"
                transform="translate(-52.78 -14.92)"
            ></path>
            <path
                d="M265.3 218.29c16.72-8.27 36.2 3.31 49.58 13a.21.21 0 01.18 0c13.49 9.63 31 24.58 28.39 43.24a17.36 17.36 0 01-7.07 12c-4.59 3.07-10.54 3.21-16 2.36-12.06-1.88-22.86-7.92-32.81-15.19C276 266 265.21 257 258.9 244.72c-2.52-4.9-4.27-10.6-2.8-15.92a17.32 17.32 0 019.2-10.51z"
                className="color2"
                data-name="arm"
                transform="translate(-52.78 -14.92)"
            ></path>
        </g>
        <g>
            <path
                d="M256 219.86H151a77.44 77.44 0 01-77.5-77.44V96.71a81.79 81.79 0 0181.8-81.79h98.45A80.24 80.24 0 01334 95.16v46.68a78 78 0 01-78 78.02z"
                className="color2"
                transform="translate(-52.78 -14.92)"
            ></path>
        <g>
                <path
                d="M172.52 189.11A76.57 76.57 0 0196 112.62a80.78 80.78 0 0180.69-80.69h55.54a79.28 79.28 0 0179.2 79.19v.93a77.14 77.14 0 01-77 77.06z"
                className="color4"
                transform="translate(-52.78 -14.92)"
                ></path>
                <path
                d="M232.26 33.93a77.19 77.19 0 0177.2 77.19v.93a75.05 75.05 0 01-75.06 75.06h-61.88A74.49 74.49 0 0198 112.62a78.69 78.69 0 0178.69-78.69h55.54m0-4h-55.51A82.78 82.78 0 0094 112.62a78.58 78.58 0 0078.49 78.49h61.91a79.14 79.14 0 0079.06-79.06v-.93a81.28 81.28 0 00-81.2-81.19z"
                className="color1"
                transform="translate(-52.78 -14.92)"
                ></path>
        </g>
        </g>
        <g className="rightEye">
        <rect
            width="13.56"
            height="12.58"
            x="80.26"
            y="88.66"
            className="color3"
            rx="4.01"
        ></rect>
        <rect
            width="13.56"
            height="12.58"
            x="93.25"
            y="75.01"
            className="color3"
            data-name="dot"
            rx="4.01"
        ></rect>
        <rect
            width="13.56"
            height="12.58"
            x="109.69"
            y="75.01"
            className="color3"
            data-name="dot"
            rx="4.01"
        ></rect>
        <rect
            width="13.56"
            height="12.58"
            x="122.52"
            y="89.89"
            className="color3"
            data-name="dot"
            rx="4.01"
        ></rect>
        </g>
        <g className="leftEye">
        <rect
            width="13.56"
            height="12.58"
            x="166.26"
            y="88.66"
            className="color3"
            data-name="dot"
            rx="4.01"
        ></rect>
        <rect
            width="13.56"
            height="12.58"
            x="179.25"
            y="75.01"
            className="color3"
            data-name="dot"
            rx="4.01"
        ></rect>
        <rect
            width="13.56"
            height="12.58"
            x="195.69"
            y="75.01"
            className="color3"
            data-name="dot"
            rx="4.01"
        ></rect>
        <rect
            width="13.56"
            height="12.58"
            x="208.52"
            y="89.89"
            className="color3"
            data-name="dot"
            rx="4.01"
        ></rect>
        </g>
    </svg>
    );
}

export default HoolaRobot;
