import ReactDOM from "react-dom";
import {Fragment, useState, useEffect, useContext} from "react";
import {AuthContext} from "../context/AuthContext.js"
import "./modal.css";


const DrugModal = ({ isOpen, hide, userid, drugs, setDrugs }) => {

  const context = useContext(AuthContext);
  const [name, setName] = useState('');
  const creator = userid;
  const [isArchived, setIsArchived] = useState(false);
  const [error, setError] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const data = {name, setName};



  const handleRegisterDrug = (e) => {
    e.preventDefault();

    const headers = {'Content-Type': 'application/json'}
    if(context.token) {
      headers.Authorization = `Bearer ${context.token}`
    }

    fetch(process.env.REACT_APP_BASEURL+'drug', {
      headers: new Headers(headers),
      method: 'POST',
      body: JSON.stringify({
        name,
        creator,
        isArchived
      })
    }).then(res => res.json()).then(res => {
      console.log(res);
      if(res.success){
        setDrugs([...drugs, res.drug]);
        hide();
      }
    });
  };
  //console.log(userid);
  return (
  isOpen &&
  ReactDOM.createPortal(
          <div className="modalContainer">
              <div className="overlay" onClick={hide}></div>
              <div className="modal">
                <div className="modalHeader">
                  <h4>Ajouter un<br/>médicament 💊</h4>
                  <button type="button" className="modal-close-button" onClick={hide}>
                    <span className="closeBtn">&times;</span>
                  </button>
                </div>
                <div className="modalContent">
                  <form onSubmit={handleRegisterDrug}>
                      <input
                        id="name"
                        type="text"
                        placeholder="MEDOC 0.25 mg"
                        required
                        value={data.name}
                        onChange={(e)=>data.setName(e.target.value)}
                      />
                    <div className="tac mt20">
                      <button type="submit" className="cta">
                        <span>Ajouter</span>
                      </button>
                    </div>
                  </form>
                </div>

              </div>
          </div>,
        document.body
      )
  )}
export default DrugModal;
