import {createContext, useState, useEffect} from 'react';

export const AuthContext = createContext();

export default({children}) => {
  const[isAdmin, setIsAdmin] = useState(false);
  const[isRecording, setIsRecording] = useState(false);
  const[userInfos, setUserInfos] = useState({});
  const[startTime, setStartTime] = useState();
  const[token, setToken] = useState(localStorage.getItem('Token') || '');
  const[isAuthenticate, setIsAuthenticate] = useState(false);

  //console.log(token);
  useEffect(() => {
    const headers = {};
    if(token) {
      headers.Authorization = `Bearer ${token}`
      fetch(process.env.REACT_APP_BASEURL+'user/profile', {
        headers: new Headers(headers),
        method: 'GET',
      }).then(res => res.json()).then(res => {
        //console.log(res);
        if(res.success){
          setIsAuthenticate(true);
          setUserInfos(res.user);
          if(res.isAdmin){
            setIsAdmin(true);
          }
        }
      });
      fetch(process.env.REACT_APP_BASEURL+'record/lastrecord', {
        headers: new Headers(headers)
      })
      .then((res)=>
        res.json()
      )
      .then((res) => {
        //console.log(res);
        if(res.success){
          if(res.record) {
            setStartTime(new Date(new Date(res.record.startTime).toString().split('GMT')[0]+' UTC').toISOString().split('.')[0].slice(0, -3));
          }
        }
      });
    }
  }, []);

  useEffect(()=> {
    if(localStorage.getItem('Token')) {
      setToken(localStorage.getItem('Token'));
    }
  },[token])

  const values = {
    token, setToken,
    isAuthenticate, setIsAuthenticate,
    isAdmin, setIsAdmin,
    isRecording, setIsRecording,
    userInfos, setUserInfos,
    startTime, setStartTime
  }

  return (
    <AuthContext.Provider value={values}>
    {children}
    </AuthContext.Provider>
  )
}
