// REACT
import ReactDOM from "react-dom";
import {useState, useEffect, useContext} from "react";

//LIBS
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

// CONTEXT
import {AuthContext} from "../context/AuthContext.js"
import {UserThemeContext} from "../context/UserThemeContext.js"

// COMPONENTS
import DrugInput from "./drugInput.js";
import FrontZones from "./frontzones.js";
import BackZones from "./backzones.js";

import "./recordModal.css";



const RecordModal = ({ isOpen, hide }) => {

  const context = useContext(AuthContext);
  const themeContext = useContext(UserThemeContext);
  const todayNow = new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0].slice(0, -3);
  const [stopTime, setStopTime] = useState(todayNow);
  const [intensity, setIntensity] = useState('');
  const [infos, setInfos] = useState('');
  const [drugs, setDrugs] = useState([]);
  //const [creator, setCreator] = useState('');
  const [errorIntensity, setErrorIntensity] = useState(false);
  const [isDisabled, setIsDisabled] = useState();
  const [selectedZones, setSelectedZones] = useState([]);



  useEffect(()=>{
    if(isOpen) {
      document.body.style.overflowY = "hidden";
      const headers = {'Content-Type': 'application/json'}
      if(context.token) {
        headers.Authorization = `Bearer ${context.token}`
      }
      fetch(process.env.REACT_APP_BASEURL+'drug/mydrugs', {
        headers: new Headers(headers),
      })
      .then((res)=>
        res.json()
      )
      .then((res)=>{
        if(res.success){
          const drugsTemp = [];
          for(let i = 0; i < res.drugs.length; i++) {
            drugsTemp.push({
              num : 0,
              name : res.drugs[i].name,
              isArchived: res.drugs[i].isArchived,
              id : res.drugs[i]._id
            });
          }
          //console.log(drugsTemp);
          setDrugs([...drugsTemp]);
        }
      })
    } else {
      document.body.style.overflowY = "auto";
    }
  },[isOpen, context.token]);




  // SVG HEAD ZONES FUNCTION
  const handleZoneClick = (e) => {
    let currentZone = e.target.getAttribute('data-zone');
    if(!selectedZones.includes(currentZone)){
        e.target.setAttribute('class', 'selected fzoneColor2');
        setSelectedZones([
            ...selectedZones,
            currentZone
        ])
    }
    else {
        e.target.setAttribute('class', 'fzoneColor2');
        let itemIndex = selectedZones.indexOf(currentZone);
        const selectedZonesTemp = [...selectedZones];
        selectedZonesTemp.splice(itemIndex, 1);
        setSelectedZones([
            ...selectedZonesTemp
        ]);
    }
  }
 // console.log(selectedZones);




  const [num, setNum]= useState(0);
  const incNum = (id) => {
    const drugsTemp = [...drugs];
    const drugFind = drugsTemp.find((drug) => (
      drug.id === id
    ))
    const index = drugsTemp.indexOf(drugFind);
    //console.log(drugsTemp);
    if(drugFind.num<10){
      drugsTemp.splice(index, 1, {
        id:  id,
        name: drugFind.name,
        num: drugFind.num + 1
      });
      setDrugs([...drugsTemp]);
    }
  };
  const decNum = (id) => {
    const drugsTemp = [...drugs];
    const drugFind = drugsTemp.find((drug) => (
      drug.id === id
    ))
    const index = drugsTemp.indexOf(drugFind);
    //console.log(drugsTemp);
    if(drugFind.num>0){
      drugsTemp.splice(index, 1, {
        id: id,
        name: drugFind.name,
        num: drugFind.num - 1
      });
      setDrugs([...drugsTemp]);
    }
  };
  const handleChange = (e, id) => {
    const drugsTemp = [...drugs];
    const drugFind = drugsTemp.find((drug) => (
      drug.id === id
    ))
    const index = drugsTemp.indexOf(drugFind);
    //console.log(drugsTemp);
    if(drugFind.num<10){
      drugsTemp.splice(index, 1, {
        id:  id,
        name: drugFind.name,
        num: e.target.value
      });
      setDrugs([...drugsTemp]);
    }
  };

  function millisToMinutes(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes;
  }

  const isBefore = (date1, date2) => {
    if(millisToMinutes(new Date(date1) - new Date(date2)) < 1) {
      console.log(millisToMinutes(new Date(date1) - new Date(date2)));
      return true
    }
    else {
      console.log(millisToMinutes(new Date(date1) - new Date(date2)));
      return false
    }
    // return date1 <= date2;
  }


  const [selected, setSelected] = useState('');

  const handleRadio = (event) => {
   setSelected(event.target.value);
   setIntensity(event.target.value);
   setErrorIntensity(false);
   //console.log(intensity);
  };


  const handleNewRecord = (e) => {
    e.preventDefault();

    const headers = {'Content-Type': 'application/json'}
    if(context.token) {
      headers.Authorization = `Bearer ${context.token}`
    }

    const drugsTemp = [];
    for(let i = 0; i < drugs.length; i++) {
      drugsTemp.push({
        num: drugs[i].num,
        drug: drugs[i].id,
        name: drugs[i].name
      });
    }
    //console.log(intensity);
    if(intensity) {
      if(!isBefore(stopTime, context.startTime) && intensity) {  
        fetch(process.env.REACT_APP_BASEURL+'record', {
          headers: new Headers(headers),
          method: 'PUT',
          body: JSON.stringify({
            startTime: context.startTime,
            stopTime,
            duration: (Date.parse(stopTime) - Date.parse(context.startTime)),
            intensity,
            infos,
            drugs:drugsTemp,
            zones: selectedZones
          })
        }).then(res => res.json()).then(res => {
          console.log(res);
          if(res.success){
            context.setIsRecording(false);
            context.setStartTime('');
            setStopTime('');
            hide();
          }
        });
      }
    }
    else {
      setErrorIntensity(true);
    }
  };




  const handleStopTime = (e) => {
    setStopTime(e.target.value);
    console.log('Date is before ? ', isBefore(e.target.value, context.startTime));
    if(!isBefore(e.target.value, context.startTime)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }
  //console.log('StartTime RecordModal : ', context.startTime);
  
  

  const [isFront, setIsFront] = useState(true);
  


  return (
  isOpen &&
  ReactDOM.createPortal(
          <div className="modalContainer" >
              <div className={themeContext.darkMode ? 'recordModal darkTheme' : 'recordModal'}>
                <div className="modalContent tac">
                  <form onSubmit={handleNewRecord}>
                    <fieldset>
                      <legend>Début et fin de crise</legend>
                        <label
                          htmlFor="startTime"
                          className="label labelDate"
                        >Début</label>
                        <input
                          id="startTime"
                          name="startTime"
                          type="datetime-local"
                          required
                          defaultValue={new Date(new Date(context.startTime).toString().split('GMT')[0]+' UTC').toISOString().split('.')[0].slice(0, -3)}
                          onChange={(e)=>context.setStartTime(e.target.value)}
                        />
                        <label
                          htmlFor="stopTime"
                          className="label labelDate"
                        >Fin</label>
                        <input
                          id="stopTime"
                          name="startTime"
                          type="datetime-local"
                          required
                          value={stopTime}
                          onChange={handleStopTime}
                        />
                        <p className="recordModalError">
                        {isBefore(stopTime, context.startTime) ? 'Verifier les dates' : ''}
                        </p>
                    </fieldset>

                    <fieldset className="zoneFieldset">
                      <legend>Où la douleur a-t-elle commencé ?</legend>
                      {selectedZones &&
                        <p className="mb30">
                          {
                            selectedZones.map((value, index) => (
                              <span className="selected-zone-name" key={index}>{value}</span>
                            ))
                          }
                        </p>
                      }
                      <MobileView>
                        <ul className="zonesNav">
                          <li onClick={() => setIsFront(true)} className={isFront ? 'active' : ''}>Avant</li>
                          <li onClick={() => setIsFront(false)} className={isFront ? '' : 'active'}>Arrière</li>
                        </ul>
                      </MobileView>

                      <BrowserView>
                        <div className="zonesWrap">
                          <FrontZones handleZoneClick={handleZoneClick}/>
                          <BackZones handleZoneClick={handleZoneClick}/>
                        </div>
                      </BrowserView>
                      <MobileView>
                        <div className="zonesWrap">
                          <div className={isFront ? 'active' : ''}><FrontZones handleZoneClick={handleZoneClick}/></div>
                          <div className={isFront ? '' : 'active'}><BackZones handleZoneClick={handleZoneClick}/></div>
                        </div>
                      </MobileView>
                      
                    </fieldset>

                    <fieldset>
                      <legend>Intensité de la crise</legend>
                      <div className="intensityRadioWrap">
                        <span className="intensityInputWrap intensity01">
                          <i className="fas fa-face-meh"></i>
                          <input
                            type="radio"
                            name="intensityRadio"
                            value="1"
                            className="intensityRadio"
                            checked={selected === '1'}
                            onChange={handleRadio}
                          />
                        </span>
                        <span className="intensityInputWrap intensity02">
                          <i className="fas fa-face-flushed"></i>
                          <input
                            type="radio"
                            name="intensityRadio"
                            value="2"
                            className="intensityRadio"
                            checked={selected === '2'}
                            onChange={handleRadio}
                          />
                        </span>
                        <span className="intensityInputWrap intensity03">
                          <i className="fas fa-face-frown"></i>
                          <input
                            type="radio"
                            name="intensityRadio"
                            value="3"
                            className="intensityRadio"
                            checked={selected === '3'}
                            onChange={handleRadio}
                          />
                        </span>
                        <span className="intensityInputWrap intensity04">
                          <i className="fas fa-face-sad-tear"></i>
                          <input
                            type="radio"
                            name="intensityRadio"
                            value="4"
                            className="intensityRadio"
                            checked={selected === '4'}
                            onChange={handleRadio}
                          />
                        </span>
                        <span className="intensityInputWrap intensity05">
                          <i className="fas fa-face-sad-cry"></i>
                          <input
                            type="radio"
                            name="intensityRadio"
                            value="5"
                            className="intensityRadio"
                            checked={selected === '5'}
                            onChange={handleRadio}
                          />
                        </span>
                      </div>
                      {errorIntensity && <p className="recordModalError">L'intensité doit être définie</p>}
                    </fieldset>

                    <fieldset>
                      <legend>As-tu pris des médicaments ?</legend>
                      {
                      (drugs.length < 1)
                      ?
                      <p className="recordModalError">Aucun médicament trouvé. Rendez-vous sur la page d'accueil pour en ajouter un ou utiliser le champ texte ci-dessous</p>
                      :
                      drugs.map((drug, index)=>{
                          return !drug.isArchived ?
                          <DrugInput key={index} drugInfos={drug} num={num} incNum={incNum} decNum={decNum} handleChange={handleChange}/>
                           : null
                        })
                      }
                    </fieldset>
                    <fieldset>
                      <legend>Quelque chose à ajouter ?</legend>
                      <textarea
                        name="infos"
                        id="textAreaInfos"
                        cols="30"
                        rows="4"
                        placeholder="Par exemple : Sommeil, Alimentation..."
                        onChange={(e)=>setInfos(e.target.value)}
                        >
                      </textarea>
                    </fieldset>

                    <div className="tac">
                      <button onClick={handleNewRecord} type="submit" className={isDisabled ? 'cta disabled' : 'cta'}>
                        <span>Enregistrer</span>
                      </button>
                    </div>
                  </form>
                </div>

              </div>
          </div>,
        document.body
      )
  )}
export default RecordModal;
