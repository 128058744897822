import {useState} from "react";
import "./drugInput.css";




function DrugInput({drugInfos, decNum, incNum, handleChange}){
  //console.log(drugInfos);
  return(
  <div className="drugInput">
    <p>{drugInfos.name}</p>
    <div className="input-group">
      <button className="btnCounter" type="button" onClick={() => decNum(drugInfos.id)}><i className="fas fa-minus"></i></button>
      <input type="number" className="form-control" value={drugInfos.num} onChange={(e) => handleChange(e, drugInfos.id)} disabled/>
      <button className="btnCounter" type="button" onClick={() => incNum(drugInfos.id)}><i className="fas fa-plus"></i></button>
    </div>
  </div>
  );
}

export default DrugInput;
