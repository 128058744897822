//LIBS
import {useEffect, useState, useContext} from 'react';
import {AuthContext} from "../context/AuthContext.js"

// COMPONENTS
import WarningModal from "../components/WarningModal.js";

//STYLES
import "./RecordTab.css";


function RecordTab({record, handleDeleteRecord}) {

    const context = useContext(AuthContext);
    const [records, setRecords] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const [isOpenTab, setIsOpenTab] = useState(false);
    const [isOpenWarning, setIsOpenWarning] = useState(false);

    const toggleModalWarning = () => { setIsOpenWarning(!isOpenWarning); }

    const dateToFormatYYYY = (theDate) => {
        theDate = new Date(theDate.toString().split('GMT')[0]+' UTC').toISOString().split('T')[0];
        return theDate;
    }
    const usToFrFormat = (usFormat) => {
        let datePart = usFormat.match(/\d+/g),
        year = datePart[0].substring(2),
        month = datePart[1], day = datePart[2];
        return day+'/'+month+'/'+year;
    }
    
    const secondsToHms = (d) => {
        d = Number(d/1000);
        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        //let s = Math.floor(d % 3600 % 60);

        let hDisplay = h > 0 ? h + (h === 1 ? " h " : " h ") : "";
        let mDisplay = m > 0 ? m + (m === 1 ? " min" : " min") : "";
        //let sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
        return hDisplay + mDisplay;
    }

    const handleOpenTab = () => {
        setIsOpenTab(!isOpenTab);
    }


    const intensityLook = (intensity) => {
        switch (intensity) {
        case '1':
            return (
            <span className="intensityInputWrap intensity01"></span>
            );
        case '2':
            return (
            <span className="intensityInputWrap intensity02"></span>
            );
        case '3':
            return (
            <span className="intensityInputWrap intensity03"></span>
            );
        case '4':
            return (
            <span className="intensityInputWrap intensity04"></span>
            );
        case '5':
            return (
            <span className="intensityInputWrap intensity05"></span>
            );
            default:
            return (
                <span className="intensityInputWrap intensity03"></span>
            )
        }
    }

    const handleConfirmDelete = () => {
        handleDeleteRecord();
        setIsOpenWarning(false);
    }

  return (
    <>
    <WarningModal action={()=>handleConfirmDelete()} isOpen={isOpenWarning} hide={toggleModalWarning} warning="Une fois supprimée, l'enregistrement sera perdu. Confirmez la suppression"/>
    <li className="elemRecord mt10">
        <header onClick={handleOpenTab}>
            <p><span className="lineLabel labelIntensity">Intensité : </span>{intensityLook(record.intensity)}</p>
            <p><span className="lineLabel">Date : </span><span>{usToFrFormat(dateToFormatYYYY(new Date(record.startTime)))}</span></p>
            <p><span className="lineLabel">Durée : </span><span>{secondsToHms(record.duration)}</span></p>
            <i className={isOpenTab ? 'openTab fas fa-chevron-up' : 'openTab fas fa-chevron-down'}></i>
        </header>
        <div className={isOpenTab ? 'elemContent openTab' : 'elemContent'}>
            <div><p><span className="lineLabel">Début : </span><span>{(new Date(record.startTime).toLocaleTimeString()).substring(0, 5).replace(':', ' h ')}</span></p></div>
            <p className="intensity"><span className="lineLabel">Intensité : </span>{record.intensity}</p>
            <div className="medoc"><span className="lineLabel">Médicament(s) :</span>{
            record.drugs.map((drug, index)=>
                {
                    if (drug.num != 0)
                    return(
                        <span className="drugTag" key={index}><span>{drug.num}</span> <span>{drug.drug.name}</span></span>
                    )
                }
            )
            }</div>
            <div className="zones"><span className="lineLabel">{record.zones > 1 ? 'Zone' : 'Zones'} :</span>{
                record.zones.join(', ')
            }</div>
            <p className="infos"><span className="lineLabel">Infos : </span><span>{record.infos ? record.infos : 'Aucune info'}</span></p>
            <div className="deleteRecord"><button className="smallCta ctaDel" onClick={() => setIsOpenWarning(true)}><i className="fas fa-ban"></i><span>Supprimer</span></button></div>
        </div>
    </li>
    </>
  )
}

export default RecordTab
