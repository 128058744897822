import ReactDOM from "react-dom";
import {Fragment, useState, useEffect} from "react";
import "./modal.css";
import Checkbox from "./Checkbox.js";


const Modal = ({ isOpen, hide}) => {

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState({});

  const [users, setUsers] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [fetchSuccess, setFetchSuccess] = useState(false);

  const data = {email, setEmail, name, setName, password, setPassword, confirmPassword, setConfirmPassword, error};



  const handleRegister = (e) => {
    e.preventDefault();

    if(password == confirmPassword){

      fetch(process.env.REACT_APP_BASEURL+'user/register', {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          email,
          name,
          password,
          confirmPassword,
        })
      }).then(res => res.json()).then(res => {
        console.log(res);
        if(res.success){
          setFetchSuccess(true);
        }
      });
    } else {
      console.log('NOT CORRECT');
      setError({
        confirmPassword: 'Password does not match'
      });
    }
  };

  const okMailSent = () => {
    hide();
    setFetchSuccess(false);
  }

  return (
  isOpen &&
  ReactDOM.createPortal(
          <div className="modalContainer">
              <div className="overlay" onClick={hide}></div>
              <div className="modal">
                <div className="modalContent">
                  {(!fetchSuccess)
                  ?
                  <>
                  <div className="modalHeader">
                    <h4>Créer un compte</h4>
                    <button type="button" className="modal-close-button" onClick={hide}>
                      <span className="closeBtn">&times;</span>
                    </button>
                  </div>
                  <form onSubmit={handleRegister}>
                      <input
                        id="email"
                        type="email"
                        placeholder="Email"
                        required
                        value={data.email}
                        onChange={(e)=>data.setEmail(e.target.value)}
                      />
                      <input
                        id="name"
                        type="text"
                        placeholder="Nom et Prénom"
                        required
                        value={data.name}
                        onChange={(e)=>data.setName(e.target.value)}
                      />
                      <input
                        id="password"
                        type="password"
                        placeholder="Mot de passe"
                        required
                        value={data.password}
                        onChange={(e)=>data.setPassword(e.target.value)}
                      />
                      <input
                        id="confirmPassword"
                        type="password"
                        placeholder="Confirmer le Mot de passe"
                        required
                        value={data.confirmPassword}
                        onChange={(e)=>data.setConfirmPassword(e.target.value)}
                      />
                      <p className="errorMsg">{data.error && data.error.confirmPassword}</p>
                    <div className="tac">
                      {/* <p className="warningMsg">⚠️ Penser à activer le compte en cliquant<br/>sur le lien qu'on vous envoie par e-mail ⚠️</p> */}
                      <button type="submit" className="cta">
                        <span>Créer mon compte</span>
                      </button>
                    </div>
                  </form>
                  </>
                  :
                  <div className="mailSent tac">
                    <i className="fas fa-circle-check"></i>
                    <p>Un email vous a été envoyé</p>
                    <button type="submit" className="cta" onClick={okMailSent}>
                        <span>Ok 👌</span>
                    </button>
                  </div>
                  }
                </div>

              </div>
          </div>,
        document.body
      )
  )}
export default Modal;
