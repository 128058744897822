import { useState, useContext, useEffect } from "react";
import {Link, useNavigate} from "react-router-dom";

import ReactDOM from "react-dom";

import {AuthContext} from "../context/AuthContext.js"

import "./modal.css";

const LoginModal = ({isOpen, hide}) => {

  const navigate = useNavigate();
  const context = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();
  const [forgetPassword, setForgetPassword] = useState(false);
  const data = {email, setEmail, password, setPassword};
  const dataFP = {email, setEmail};
  const [info, setInfo] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    fetch(process.env.REACT_APP_BASEURL+'user/login', {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
      },
      method: 'POST',
      body: JSON.stringify({
        email,
        password
      })
    }).then(res => res.json()).then(res => {
      //console.log(res);
      if(res.success && res.isConfirmed){
        context.setUserInfos(res.name);
        localStorage.setItem('Token', res.token);
        context.setIsAuthenticate(true);
        context.setToken(res.token);
        //console.log('user');
        hide();
        navigate('/');
        window.location.reload(false);
      }
      else if(!res.success ) {
        setError("Email or password doesn't match");
      }
      else if(res.isConfirmed) {
        setError("Check your mailbox ! Account not confirmed yet");
      }
    });
  }

  const handleForgetPassword = (e) => {
    e.preventDefault();
    if(!localStorage.getItem('ForgetToken')){
      fetch(process.env.REACT_APP_BASEURL+'user/forget-password', {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          email: dataFP.email,
        })
      }).then(res => res.json()).then(res => {
        if(res.success){
          setInfo(res.msg);
          setTimeout(() => setInfo(''), 1500);
        }else{
          setInfo(res.msg);
        }
        if(res.token){
          localStorage.setItem('ForgetToken', res.token);
        }
      }).catch(console.error)
    }else{
      setInfo('Checked your mailbox')
    }
  }
  useEffect(() => {
    setForgetPassword(false);
    setEmail('');
    setInfo('');
  }, [isOpen])

  return (
  isOpen &&
    ReactDOM.createPortal(
          <div className="modalContainer">
              <div className="overlay" onClick={hide}></div>
              <div className="modal">
                <div className="modalHeader">
                  <h4>{forgetPassword ? 'Oops 😥' :'Hello 👋'}</h4>
                  <button type="button" className="modal-close-button" onClick={hide}>
                    <span className="closeBtn">&times;</span>
                  </button>
                </div>
                <div className="modalContent">
                  {(forgetPassword)
                    ? <form onSubmit={handleForgetPassword}>
                        <p className="tac mb20">Entrez votre adresse. Un email vous sera envoyé</p>
                        <input id="email" type="email" placeholder="Email" required value={dataFP.email} onChange={(e)=>dataFP.setEmail(e.target.value)} />
                        <p className="">{info}</p>
                        <div className="tac flexSpaceBetween mt20">
                        <span className="ctaLink specialLink" onClick={() => setForgetPassword(false)}><i className="fas fa-arrow-left mr10"></i> Retour</span>
                          <button type="submit" className="cta ml20">
                            <span>Confirmer</span>
                          </button>
                        </div>
                      </form>
                    : <form onSubmit={handleLogin}>
                        <input id="email" type="email" placeholder="Email" required value={data.email} onChange={(e)=>data.setEmail(e.target.value)} />
                        <input id="password" type="password" placeholder="Password" required value={data.password} onChange={(e)=>data.setPassword(e.target.value)} />
                        <p className="errorMsg">{error}</p>
                        <div className="tac mb20">
                          <button type="submit" className="cta">
                            <span>Se Connecter</span>
                          </button>
                        </div>
                        <p className="specialLink tac"><span className="ctaLink" onClick={() => setForgetPassword(true)}>Mot de passe oublié ?</span></p>
                      </form>
                  }
                </div>

              </div>
          </div>,
        document.body
      )

  )}
export default LoginModal;
