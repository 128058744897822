// COMPONENTS
import HoolaRobot from '../components/HoolaRobot.js';
// STYLES
import "./BasicPage.css";
import "./About.css";


function About() {
  return (
    <div className="PageAbout">
      <main id="About">
        <div className="wrap">
          {<HoolaRobot anim="hello"></HoolaRobot>}
          <div className="content">
            <h1 className="mb30">À propos de HoolApp</h1>
            <p>HoolApp est un outil <span className="color-green">100% gratuit</span></p>
          </div>
        </div>
      </main>
      
      <section className="about01">
        <div className="wrap flex">
          <img src="/app-screen.png" alt="Illustration d'un aperçu de l'application" />
          <div className="content">
            <h2 className="mb30">Mieux suivre vos maux de tête</h2>
            <ul>
              <li className="mb20">
                <span><i className="fas fa-shield-halved"></i></span>
                <p>Gratuit, pratique et sécurisé</p>
              </li>
              <li className="mb20">
                <span><i className="fas fa-hand-holding-medical"></i></span>
                <p>Mieux comprendre vos maux de tête: typologie, déclencheurs...</p>
              </li>
              <li className="mb20">
                <span><i className="fas fa-user-doctor"></i></span>
                <p>Communiquer avec votre médecin plus facilement</p>
              </li>
              <li className="mb20">
                <span><i className="fas fa-file-medical"></i></span>
                <p>Obtenir un rapport mensuel</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="aboutWhy">
        <div className="wrap flex">
          <div>
            <h2 className="mb30">Pourquoi HoolApp</h2>
            <p className="mb20">HoolApp a été créé avec passion et empathie, pour mieux prendre en charge les maux de tête.</p>
            <p className="mb20">Vos données vous appartiennent, elles ne sont jamais réutilisées. C'est gratuit car nous sommes des gens biens 😎</p>
            <p>Ergonomique, crosseplatforme et éco-conceptualisé.</p>
          </div>
          <div>
            <h2 className="mb30">Comment ça marche</h2>
            <p className="mb20">À chaque mal de tête, rendez-vous sur HoolApp pour appuyer sur le bouton d'enregistrement.</p>
            <p className="mb20">Une fois la douleur dissipée, revenez sur l'App pour terminer l'enregistrement.</p>
            <p>Mesurez vos maux de tête, chaque mois.</p>
          </div>
        </div>
      </section>
      <hr className="wrap"/>
      <section className="justDoIt">
        <div className="wrap tac">
          <img src="/headache.png" alt="Illustration d'un aperçu de l'application" />
          <p className="quote tac"><strong>Il ne vous reste plus qu'à prendre vos maux de tête en main !</strong></p>
        </div>
      </section>
    </div>
  );
}

export default About;
