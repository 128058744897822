import { useEffect, useState, useContext } from 'react';

import {Link, useNavigate} from "react-router-dom";

import {AuthContext} from "../context/AuthContext.js"
import {UserThemeContext} from "../context/UserThemeContext.js"

import LoginModal from "./loginModal";
import Modal from "../components/modal";

import "./navBar.css";


const NavBar = () => {

  if (window.scrollY) {
    window.scroll(0, 0);  // reset the scroll position to the top left of the document.
  }

  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const themeContext = useContext(UserThemeContext);

  const [isOpenRegister, setIsOpenRegister] = useState(false);
  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [isOpenNav, setIsOpenNav] = useState(false);


  const toggleNav = () => {
    setIsOpenNav(!isOpenNav);
  }

  const toggleModalLogin = () => {
    setIsOpenLogin(!isOpenLogin);
  }
  const toggleModalRegister = () => {
    setIsOpenRegister(!isOpenRegister);
  }

  const disconnectUser = () => {
    context.setIsAuthenticate(false)
    context.setIsAdmin(false)
    localStorage.removeItem('Token')
    navigate('/')
  }
  

  const handleDarkMode = (e) => {
    e.preventDefault();
    themeContext.setDarkMode(!themeContext.darkMode);
    if(themeContext.darkMode) {
      localStorage.removeItem('darkMode');
    } else {
      localStorage.setItem('darkMode', true);
    }
  }


  if(context.isAuthenticate) {
    // context.isAdmin
    //context.userInfos

    // USER LOGGED
    return (
      <nav id="globalHeader" className={isOpenNav ? 'navOpen' : ''}>
        <div className="navWrap">
          <Link to='/' onClick={isOpenNav ? toggleNav : undefined}><img height="50" className="logo" src="/logo.svg" alt="HoolApp Logo"/></Link>
          <div className="mobileMenu" onClick={toggleNav}><span></span></div>
          <ul>
            <li onClick={toggleNav}><Link to='/records'><i className="fas fa-list"></i> Enregistrements <i className="chevron fas fa-chevron-right"></i></Link></li>
            <li onClick={toggleNav}><Link to='/reports'><i className="fas fa-file-export"></i> Rapports <i className="chevron fas fa-chevron-right"></i></Link></li>
            <li onClick={toggleNav}><Link to='/drugs'><i className="fas fa-capsules"></i> Médicaments <i className="chevron fas fa-chevron-right"></i></Link></li>
            <li onClick={toggleNav}><Link to='/profile'><i className="fas fa-user"></i> Profil <i className="chevron fas fa-chevron-right"></i></Link></li>
            <li className="switchMenu"><span className="switchLabel">Theme Light</span> <button onClick={handleDarkMode} className="switchTheme"><span className="light"></span><span className="dark"></span></button> <span className="switchLabel">Theme Dark</span></li>
          </ul>
        </div>
      </nav>
    )
  }
  // NOT LOGGED
  return (
    <>
    <LoginModal isOpen={isOpenLogin} hide={toggleModalLogin}/>
    <Modal isOpen={isOpenRegister} hide={toggleModalRegister}/>
    <nav id="globalHeader" className={isOpenNav ? 'navOpen' : undefined}>
      <div className="navWrap wrapXL">
        <Link to='/' onClick={isOpenNav ? toggleNav : undefined}><img height="50" className="logo" src="/logo.svg" alt="HoolApp Logo"/></Link>
        <div className="mobileMenu" onClick={toggleNav}><span></span></div>
        <ul>
          <li onClick={toggleNav}><Link to='/about'>À propos <i className="chevron fas fa-chevron-right"></i></Link></li>
          <li onClick={toggleModalRegister} className="registerBtn"><a className="cta">Créer un compte</a></li>
          <li onClick={toggleModalLogin} className="loginBtn"><a>Connexion</a></li>
          <li className="switchMenu"><span className="switchLabel">Theme Light</span> <button onClick={handleDarkMode} className="switchTheme"><span className="light"></span><span className="dark"></span></button> <span className="switchLabel">Theme Dark</span></li>
        </ul>
      </div>
    </nav>
    </>
  )


}
export default NavBar;
